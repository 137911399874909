:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --accent-color: #5caad9;
  --warning-primary: #fd2d52;
  --warning-bg: #ffd5d5;
}

body {
  margin: 0;
}

.rbc-event {
  background-color: var(--background-color);
  color: var(--text-color);
}
.rbc-event.rbc-selected {
  background-color: var(--accent-color);
}
.rbc-toolbar {
  .rbc-btn-group {
    display: none;
  }
}
.rbc-off-range {
  visibility: hidden;
}
.rbc-off-range-bg {
  z-index: 5;
  background: rgb(243, 243, 243);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  overflow-x: hidden;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content {
  overflow-x: hidden !important;
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #dbdbdb;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #414141;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
