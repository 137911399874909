@font-face {
  font-family: 'PermianSansTypeface';
  font-style: normal;
  font-weight: normal;
  src: local('PermianSansTypeface Regular'), url('../public/fonts/PermianSansTypeface.woff') format('woff');
}

@font-face {
  font-family: 'PermianSansTypeface';
  font-style: italic;
  font-weight: normal;
  src: local('PermianSansTypeface Italic'), url('../public/fonts/PermianSansTypeface-Italic.woff') format('woff');
}

@font-face {
  font-family: 'PermianSansTypeface';
  font-style: normal;
  font-weight: bold;
  src: local('PermianSansTypeface Bold'), url('../public/fonts/PermianSansTypeface-Bold.woff') format('woff');
}

body {
  font-family: -apple-system, PermianSansTypeface, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}
